const TextSection = () => {
    return (
        <div className="contain-text">
            <h1 className="text-4xl font-bold dark:text-white">Google Reverse Image Search</h1>
            <p className="font-light text-gray-500 dark:text-gray-400">Reverseimgsearch allows you to search by image on your favorite photos, friends' photos and more using the Google reverse image search api. Search using photos instead of text, on desktop or mobile.</p>
            <p className="font-light text-gray-500 dark:text-gray-400">One limitation of Google's reverse image search functionality is the inability to search by image on mobile devices. Google restricts this functionality to desktop users only. Reverseimgsearch allows you to search by image on any device.</p>
            <h2 className="text-3xl font-bold dark:text-white">What is Reverse Image Search?</h2>
            <p className="font-light text-gray-500 dark:text-gray-400">When you upload an image using our file uploader we use Google to reverse image search the web for the most similar images. This includes similar color and content. You will see 50 to 100 of the most relevant photos appear in seconds, and you can click through to view the origin of the image to download or view the image in its true size.</p>
            <h2 className="text-3xl font-bold dark:text-white">Google Reverse Image Search Iphone</h2>
            <p className="font-light text-gray-500 dark:text-gray-400">Google does not currently support reverse image search on Iphone or other mobile devices. You can use our image reverse search functionality to bypass this and get Google's top similar images based on the file you upload. Simply click the file upload button and start reverse searching your images right away!</p>
        </div>
    )
}

export default TextSection;