import logo from './logo.svg';
import './App.css';
import HomePage from "./components/homepage.js"
import { useEffect } from 'react';
import Navbar from "./components/Navbar.js"

function App() {


  return (
    <div className="App dark:bg-nearblack">
      <Navbar />
      <header className="upload-header dark:bg-html-dark">
        <div className="upload-container">
          <HomePage />
        </div>
      </header>

    </div >
  );
}

export default App;
