const DataShow = ({ data }) => {
    if (data[1]) {
        console.log("got data")
        return (
            <div className="imgcontainer">
                {data.map((each) => {
                    return (
                        <a href={each.link}>
                            <img class="h-auto rounded-lg" src={each.img} alt="image description" />
                            <figcaption class="truncate mt-2 text-sm text-center text-gray-500 dark:text-gray-400">{each.alt}</figcaption>
                        </a>
                    )
                })}
            </div>
        )
    }
}

export default DataShow