import { useEffect, useState } from "react";
import DataShow from "./DataShow"
import TextSection from "./TextSection"
import ClipLoader from "react-spinners/ClipLoader";
import { BounceLoader, HashLoader, MoonLoader, PuffLoader } from "react-spinners";



const HomePage = () => {
    const [fetchStr, setFetchStr] = useState("")
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        var isLocal = window.location.href.includes("://localhost");

        if (isLocal) {
            setFetchStr("http://localhost:4001/api/basic/upload");
        } else {
            setFetchStr("https://reverseimgsearchbackend.com/api/basic/upload");
        }

    })

    const handleChange = () => {
        var input = document.querySelector('input[type="file"]');

        var fd = new FormData();
        fd.append("file", input.files[0]);
        console.log(input.files);
        setLoading(true)

        fetch(fetchStr, {
            method: "POST",
            body: fd,
        })
            .then((res) => res.json())
            .then((data) => {
                //   console.log(data[0]);
                setLoading(false)

                console.log(data)
                setData(data)
            });


    }

    const backToTop = () => {
        window.location.href = "#top"
    }


    const override = {
        display: "block",
        margin: "0 auto",
        marginBottom: "30px",
        borderColor: "red",
    };



    if (!loading) {
        return (
            <div id="#top">
                <div class="flex justify-center items-center w-full uploadbtn">
                    <label for="dropzone-file" class="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div class="flex flex-col justify-center items-center pt-5 pb-6">
                            <svg aria-hidden="true" class="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        </div>
                        <input onChange={handleChange} id="dropzone-file" type="file" class="hidden" />
                    </label>
                </div>
                <DataShow data={data} />
                {data[0] ? <button type="button" onClick={backToTop} class="back-to-top-button text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Back to Top</button> : <div></div>}
                <TextSection />
            </div>)

    } else {


        return (
            <div id="#top">
                <PuffLoader
                    color={"blue"}
                    loading={loading}
                    cssOverride={override}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />

                <DataShow data={data} />
                {data[0] ? <button type="button" onClick={backToTop} class="back-to-top-button text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Back to Top</button> : <div></div>}
                <TextSection />

            </div >
        )
    }

}

export default HomePage;